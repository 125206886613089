// import React from "react";

// const ProductDetail = () => {
//   return <div />;
// };

// export default ProductDetail;

import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Img from "gatsby-image";
import Layout from "@components/layout";
import Header from "@components/header";
import SEO from "@components/seo";
import { RichText } from "prismic-reactjs";
import { StoreContext } from "@Context/store-context.js";
import styles from "@scss/shop.module.scss";

// import "./../scss/shop.scss";
// import "./../scss/page.scss";

// class ProductDetail extends Component {
const ProductDetail = ({ data }) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     activeSlideIdx: 0,
  //     variantId: null,
  //     variantPrice: null,
  //     buttonText: "Add to Cart",
  //     addToCartDisabled: false
  //   };
  //   this.changeSlide = this.changeSlide.bind(this);
  // }
  // componentDidMount() {
  //   let { props } = this;

  // }
  // const { addVariantToCart, store } = useContext(StoreContext);
  const Store = useContext(StoreContext);

  const firstVariantData = get(data, "product.variants[0]");
  let firstVariant = null;
  let firstPrice = null;

  if (firstVariantData.title === "Default Title") {
    firstVariant = firstVariantData.shopifyId;
    firstPrice = firstVariantData.price;
  } else {
    firstPrice = firstVariantData.price;
    // setAddToCartDisabled(true);
  }

  const [slideIdx, setSlideIdx] = useState(0);
  const [variantId, setVariantId] = useState(firstVariant);
  const [variantPrice, setVariantPrice] = useState(firstPrice);
  const [buttonText, setButtonText] = useState("Add to Cart");
  const [addToCartDisabled, setAddToCartDisabled] = useState(false);

  const changeSlide = (e, newSlideIdx) => {
    e.preventDefault();
    setSlideIdx(newSlideIdx);
  };
  const product = get(data, "product");
  const globals = get(data, "prismic.globals");
  return (
    <Layout>
      <SEO title="Product" />
      <Header isInterior={true} />
      <section className={styles.shopDetail}>
        <div className={styles.shopDetail__content}>
          <div className={styles.shopDetail__main}>
            <div className={styles.shopDetail__photos}>
              <ul className={styles.shopDetail__photosList}>
                {product.images &&
                  product.images.map((photo, idx) => {
                    let { fullsizeFluid } = photo.localFile.childImageSharp;
                    if (slideIdx !== idx) {
                      return null;
                    }
                    return (
                      <li key={idx}>
                        <Img fluid={fullsizeFluid} />
                      </li>
                    );
                  })}
              </ul>
              {product.images.length > 1 && (
                <ul className={styles.shopDetail__photosThumbnails}>
                  {product.images.map((photo, idx) => {
                    let { thumbnailFixed } = photo.localFile.childImageSharp;
                    return (
                      <li key={idx}>
                        <a
                          href="#"
                          onClick={e => {
                            changeSlide(e, idx);
                          }}
                        >
                          <Img fluid={thumbnailFixed} />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className={styles.shopDetail__info}>
              <div className={styles.shopDetail__inner}>
                <h3>{product.title}</h3>
                <strong className={styles.shopDetail__price}>
                  ${Math.round(variantPrice)}
                </strong>
                <div
                  className={styles.shopDetail__copy}
                  dangerouslySetInnerHTML={{
                    __html: product.descriptionHtml
                  }}
                />
                <div className={styles.shopDetail__variants}>
                  {product.variants.map((variant, idx) => {
                    let altText = !variant.availableForSale
                      ? `${variant.title} Sold Out`
                      : variant.title;
                    let isDisabled = !variant.availableForSale;
                    if (variant.title === "Default Title") {
                      return null;
                    }
                    return (
                      <div className={styles.shopDetail__variant}>
                        <input
                          key={idx}
                          id={variant.id}
                          name={`temp`}
                          type="radio"
                          value={variant.shopifyId}
                          disabled={isDisabled}
                          onChange={e => {
                            setVariantId(variant.shopifyId);
                            setVariantPrice(variant.price);
                            setAddToCartDisabled(false);
                          }}
                        />
                        <label title={altText} htmlFor={variant.id}>
                          {variant.title}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <button
                  type="button"
                  disabled={addToCartDisabled}
                  onClick={() => {
                    setButtonText("Adding...");
                    Store.addProductToCart(variantId, 1, () => {
                      setButtonText("Added!");
                      setTimeout(() => {
                        setButtonText("Add to Cart");
                      }, 2000);
                    });
                  }}
                >
                  {buttonText}
                </button>
                <div className={styles.shopDetail__description}>
                  <RichText render={globals.product_detail_term_text} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <GlobalFooter /> */}
    </Layout>
  );
};

export default ProductDetail;

export const productDetailQuery = graphql`
  query productDetailQuery($handle: String) {
    product: shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      description
      descriptionHtml
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
      }
      images {
        originalSrc
        localFile {
          childImageSharp {
            fullsizeFluid: fluid(maxHeight: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
            thumbnailFixed: fluid(maxHeight: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }

    prismic {
      globals: options(uid: "options", lang: "en-us") {
        title
        product_detail_term_text
      }
    }
  }
`;
